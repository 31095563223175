
import { defineComponent, ref, onMounted, onActivated } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import FilterDropdown from "@/views/warehouse-management/stock-in/FilterDropdown.vue";
import FsLightbox from "fslightbox-vue/v3";
import { MenuComponent } from "@/assets/ts/components";
import { ApiBase, ApiFulfillmentOrders, ApiStock } from "@/core/api";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import {
  FilterOption,
  SortOption,
  TaggingItem,
  WarehouseOption,
} from "@/core/directive/interface/common";
import { StockInTable } from "@/core/directive/interface/stockIn";
import { previewOrderImages } from "@/core/directive/function/order";
import { ProductItemImage } from "@/core/directive/interface/order";
import store from "@/store";
import {
  commonChangeFilterAddDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "warehouse-management-stock-in",
  components: {
    MBDatatable,
    FilterDropdown,
    FsLightbox,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<StockInTable>>([]);
    const checkedCompanys = ref([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);

    const tableHeader = ref([
      {
        name: t("order.image"),
        key: "image",
        sortable: false,
      },
      {
        name: t("order.articleInformation"),
        key: "article_information",
        sortable: false,
      },
      {
        name: t("order.articleAttributes"),
        key: "article_attributes",
        sortable: false,
      },
      {
        name: t("shipments.trackingNo"),
        key: "tracking_no",
        sortable: true,
      },
      {
        name: t("stockIn.receivedDate"),
        key: "received_date",
        sortable: false,
      },
      {
        name: t("stockIn.warehouse"),
        key: "warehouse_id",
        sortable: false,
      },
      {
        name: t("stockIn.fulfillmentOrderNo"),
        key: "fulfillment_order_number",
        sortable: false,
      },
      {
        name: t("stockIn.salesOrderNo"),
        key: "sale_order_number",
        sortable: false,
      },
      {
        name: t("stockIn.inventoryTarget"),
        key: "inventory_target",
        sortable: false,
      },
      {
        name: t("stockIn.stockInQuantity"),
        key: "stock_in_quantity",
        sortable: false,
        align: "right",
      },
      {
        name: t("stockIn.status"),
        key: "status",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const options = ref({
      warehouseOptions: [] as WarehouseOption[],
      statusOptions: [] as TaggingItem[],
      inventoryTargetOptions: [] as TaggingItem[],
      warehouse: new Map([]),
      consigner: new Map([]),
      product_items: [],
      loadingProducts: true,
      toggler: false,
      sources: [] as any[],
      sourcesIndex: -1,
      sourcesSlide: 1,
    });

    const getWarehouseData = async () => {
      const { data } = await ApiFulfillmentOrders.getWarehouseData({
        max_item: "all",
      });
      if (data.code == 0) {
        data.data.map((item) => {
          options.value.warehouse.set(item.id, item.label);
        });
        options.value.warehouseOptions = data.data;
      }
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["stock_In_status", "stock_in_inventory_target"],
      });
      if (data.code == 0) {
        options.value.statusOptions = data.data.stock_In_status.items;
        options.value.inventoryTargetOptions =
          data.data.stock_in_inventory_target.items;
      }
    };

    const previewImages = (
      previewImages: ProductItemImage[] | undefined,
      imageIndex: number
    ) => {
      options.value.sources = previewOrderImages(previewImages);
      options.value.sourcesIndex = imageIndex;
      options.value.sourcesSlide = imageIndex + 1;
      options.value.toggler = !options.value.toggler;
    };

    const init = () => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getStockInList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
      if (options.value.warehouse.values.length === 0) {
        getWarehouseData();
      }
      if (
        options.value.statusOptions.length === 0 ||
        options.value.inventoryTargetOptions.length === 0
      ) {
        getTaggingData();
      }
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    const getStockInList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiStock.getStockInList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            let ids: any = [];
            tableData.value.forEach((item: any) => {
              item.downloadDisabled = false;
            });
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      getStockInList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getStockInList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getStockInList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (
            item == "warehouse_id" ||
            item == "consigner" ||
            item == "inventory_target"
          ) {
            if (val[item].length != 0)
              filterArr.push({
                field: item,
                value: val[item],
                condition: "in",
              });
          } else if (
            item == "fulfillment_order_number" ||
            item == "tracking_no" ||
            item == "sale_order_number"
          ) {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          } else if (item == "received_date_start") {
            filterArr.push({
              field: "received_date",
              value: commonChangeFilterAddDate(val[item], 1),
              condition: "gteq",
            });
          } else if (item == "received_date_end") {
            filterArr.push({
              field: "received_date",
              value: commonChangeFilterAddDate(val[item]),
              condition: "lteq",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getStockInList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getStockInList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getStockInList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const statusClass = (status) => {
      let statusClass = "",
        str = "";
      switch (status) {
        case "5":
          str = "In Transit"; // 在途
          statusClass = "badge-warning";
          break;
        case "10":
          str = "Package Received"; // 包裹接收
          statusClass = "badge-light-primary";
          break;
        case "20":
          str = "Source Matching"; // 来源匹配
          statusClass = "badge-light-info";
          break;
        case "30":
        case "40":
          str = status == "30" ? "Checking" : "Product Matching"; // 检查中 商品匹配
          statusClass = "badge-light-warning";
          break;
        case "50":
          str = "Checking Abnormal"; // 检查异常
          statusClass = "badge-light-danger";
          break;
        case "60":
        case "70":
          str = status == "60" ? "Checking Completed" : "Ready For Storage"; // 检查完毕 准备入库
          statusClass = "badge-light-info-custom";
          break;
        case "80":
          str = "Storage Completed"; // 入库完毕
          statusClass = "badge-light-success";
          break;
        default:
          statusClass = "badge-light-primary";
      }
      return statusClass;
    };

    const fileExport = (id, item) => {
      item.downloadDisabled = true;
      // ApiStock.print({
      //   id: id,
      //   download_type: "stream",
      // })
      //   .then((data) => {
      //     console.log(data.headers["content-disposition"]);
      //     item.downloadDisabled = false;
      //     const disposition = data.headers["content-disposition"];
      //     fileDownload(data.data, disposition.split("filename=")[1]);
      //   })
      //   .catch((error) => {
      //     item.downloadDisabled = false;
      //     console.log(error);
      //   });
    };

    return {
      t,
      formatDateTime,
      loading,
      tableHeader,
      tableData,
      options,
      currentPage,
      total,
      pageSize,
      search,
      checkedCompanys,
      getStockInList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      removeSearch,
      handleFilter,
      handleFilterReset,
      updateList,
      statusClass,
      fileExport,
      previewImages,
    };
  },
});
