import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-4" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = {
  key: 0,
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_9 = {
  type: "button",
  class: "btn btn-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end"
}
const _hoisted_10 = { class: "svg-icon svg-icon-2" }
const _hoisted_11 = { class: "card-body pt-0" }
const _hoisted_12 = { style: {"width":"150px","min-height":"100px"} }
const _hoisted_13 = { style: {"min-width":"220px"} }
const _hoisted_14 = { style: {"min-width":"220px"} }
const _hoisted_15 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_FilterDropdown = _resolveComponent("FilterDropdown")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MBDatatable = _resolveComponent("MBDatatable")!
  const _component_FsLightbox = _resolveComponent("FsLightbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen021.svg" })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
              class: "form-control form-control-solid w-250px ps-14",
              placeholder: _ctx.t('common.search')
            }, null, 40, _hoisted_6), [
              [_vModelText, _ctx.search]
            ]),
            _withDirectives(_createElementVNode("span", {
              class: "svg-icon svg-icon-1 position-absolute top-50 end-0 mr-2 translate-middle",
              style: {"cursor":"pointer"},
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removeSearch && _ctx.removeSearch(...args)))
            }, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
            ], 512), [
              [_vShow, _ctx.search != '']
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.checkedCompanys.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("button", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, [
                    _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen031.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.t("common.filter")), 1)
                ]),
                _createVNode(_component_FilterDropdown, {
                  onSubmitFilter: _ctx.handleFilter,
                  onResetFilter: _ctx.handleFilterReset,
                  option: _ctx.options
                }, null, 8, ["onSubmitFilter", "onResetFilter", "option"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_MBDatatable, {
          loading: _ctx.loading,
          "table-data": _ctx.tableData,
          "table-header": _ctx.tableHeader,
          "enable-items-per-page-dropdown": true,
          "current-page": _ctx.currentPage,
          total: _ctx.total,
          "rows-per-page": _ctx.pageSize,
          onCurrentChange: _ctx.onCurrentPageChange,
          onItemsPerPageChange: _ctx.onRowsPerPageChange,
          onSort: _ctx.onColumnSort
        }, {
          "cell-image": _withCtx(({ row }) => [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_el_carousel, {
                "indicator-position": "none",
                height: "100px",
                autoplay: false,
                arrow: "never"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.relation_sku.gallery, (item, index) => {
                    return (_openBlock(), _createBlock(_component_el_carousel_item, {
                      key: index,
                      class: "d-flex justify-content-center align-items-center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_image, {
                          style: {"width":"100px","height":"100px","cursor":"pointer"},
                          src: item.origin_url + '?x-oss-process=image/resize,l_200',
                          fit: "contain",
                          onClick: ($event: any) => (_ctx.previewImages(row.relation_sku.gallery, index))
                        }, null, 8, ["src", "onClick"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 2
              }, 1024)
            ])
          ]),
          "cell-article_information": _withCtx(({ row }) => [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("p", null, _toDisplayString(row.relation_sku.name_en), 1),
              _createElementVNode("p", null, _toDisplayString(row.relation_sku.name), 1),
              _createElementVNode("p", null, _toDisplayString(row.relation_sku.brand), 1),
              _createElementVNode("p", null, _toDisplayString(row.relation_sku.brand_article_no), 1),
              _createElementVNode("p", null, _toDisplayString(row.relation_sku.merchant_article_no), 1)
            ])
          ]),
          "cell-article_attributes": _withCtx(({ row }) => [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("p", null, _toDisplayString(_ctx.t("order.size")) + ": " + _toDisplayString(row.relation_sku.size), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.t("order.color")) + ": " + _toDisplayString(row.relation_sku.original_color), 1)
            ])
          ]),
          "cell-tracking_no": _withCtx(({ row: item }) => [
            _createVNode(_component_router_link, {
              to: 
              '/warehouse-management/stock-in/' +
              item.id +
              '/' +
              item.stock_in_id +
              '/edit'
            ,
              class: "text-gray-800 text-hover-primary mb-1"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.tracking_no), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          "cell-received_date": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.received_date), 1)
          ]),
          "cell-warehouse_id": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.__show.warehouse_name), 1)
          ]),
          "cell-fulfillment_order_number": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.fulfillment_order_number), 1)
          ]),
          "cell-sale_order_number": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.sale_order_number), 1)
          ]),
          "cell-inventory_target": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.__show.inventory_target), 1)
          ]),
          "cell-stock_in_quantity": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.stock_in_quantity), 1)
          ]),
          "cell-status": _withCtx(({ row: item }) => [
            _createElementVNode("span", {
              class: _normalizeClass(["badge text-uppercase", _ctx.statusClass(item.status)])
            }, _toDisplayString(item.__show.status), 3)
          ]),
          "cell-actions": _withCtx(({ row: item }) => [
            (item.status != '5')
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: 
              '/warehouse-management/stock-in/' +
              item.id +
              '/' +
              item.stock_in_id +
              '/edit'
            ,
                  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_15, [
                      _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/art/art005.svg" })
                    ])
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange", "onSort"])
      ])
    ]),
    _createVNode(_component_FsLightbox, {
      toggler: _ctx.options.toggler,
      sources: _ctx.options.sources,
      slide: _ctx.options.sourcesSlide
    }, null, 8, ["toggler", "sources", "slide"])
  ], 64))
}