
import { defineComponent, ref, onMounted } from "vue";
import { ApiFulfillmentOrders } from "@/core/api";
import _ from "lodash";
import { useI18n } from "vue-i18n";

interface Filter {
  status: string;
  warehouse_id: Array<number | string>;
  tracking_no: string;
  fulfillment_order_number: string;
  sale_order_number: string;
  sku_gtin: string;
  sku_identifier: string;
  inventory_target: Array<number | string>;
  received_date_start: null | string;
  received_date_end: null | string;
}

export default defineComponent({
  name: "filter-stock-in-dropdown",
  components: {},
  props: {
    option: { type: Object, required: true },
  },
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const formData = ref<Filter>({
      status: "",
      warehouse_id: [],
      tracking_no: "",
      fulfillment_order_number: "",
      sale_order_number: "",
      sku_gtin: "",
      sku_identifier: "",
      inventory_target: [],
      received_date_start: null,
      received_date_end: null,
    });

    const options = ref({
      status: [],
      warehouse: [],
      inventory_target: [],
    });

    const getWarehouseData = async () => {
      const { data } = await ApiFulfillmentOrders.getWarehouseData({});
      if (data.code == 0) {
        options.value.warehouse = data.data;
      }
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    const getOptions = () => {
      // getWarehouseData();
    };

    onMounted(() => {
      getOptions();
    });

    return {
      t,
      props,
      formData,
      formRef,
      options,
      submit,
      handleReset,
      getOptions,
    };
  },
});
